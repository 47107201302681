import styled from 'styled-components'
import { Text } from 'components/atoms/Text'
import { ReactNode } from 'react'

export interface NotificationProps {
  className?: string
  children: ReactNode
  icon?: ReactNode
  mode?: 'light' | 'dark' | 'warning' | 'error' | 'gray'
  childrenContainerTag?: 'p' | 'div'
}

export const Notification = ({
  children,
  icon,
  className,
  mode = 'light',
  childrenContainerTag,
}: NotificationProps) => {
  return (
    <NotificationContainer className={className} mode={mode}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <Text variant="body2" as={childrenContainerTag}>
        {children}
      </Text>
    </NotificationContainer>
  )
}

export const NotificationContainer = styled.div<Pick<NotificationProps, 'mode'>>`
  display: flex;
  gap: 4px;
  padding: 6px 16px;
  align-items: center;
  background: ${({ theme, mode }) => {
    switch (mode) {
      case 'error':
        return theme.colors.MistyRose
      case 'warning':
        return theme.colors.Antique
      case 'dark':
        return 'rgba(2, 79, 79, 0.2)'
      case 'gray':
        return theme.colors.GrayNurse
      case 'light':
      default:
        return theme.colors.AquaSpring
    }
  }};

  & ${Text} {
    color: ${({ theme, mode }) => {
      switch (mode) {
        case 'error':
          return theme.colors.PersianRed
        case 'warning':
          return theme.colors.Fulvous
        case 'dark':
          return theme.colors.MonteCarlo
        case 'gray':
          return theme.colors.Corduroy
        case 'light':
        default:
          return theme.colors.Elm
      }
    }};
  }
`

const IconWrapper = styled.div`
  padding-top: 2px;
`
