import * as yup from 'yup'
import { isAddress } from 'ethers/lib/utils'
import {
  floatNumberError,
  nonDecimalError,
  nonNegativeNumberError,
  positiveIntegerNumberError,
  positiveNumberError,
  uploadError,
} from './errorMessages'

export const numberIntegerPositiveSchema = yup
  .number()
  .typeError(positiveIntegerNumberError)
  .positive(positiveIntegerNumberError)
  .integer(positiveIntegerNumberError)

export const numberPositiveSchema = yup.number().typeError(positiveNumberError).positive(positiveNumberError)
export const nonNegativeIntegerSchema = yup
  .number()
  .min(0, nonNegativeNumberError)
  .typeError(nonNegativeNumberError)
  .integer(nonDecimalError)

export const nonNegativeFloatNumberSchema = (decimals: number) =>
  yup
    .number()
    .min(0)
    .typeError(positiveNumberError)
    .test('fits-decimals', floatNumberError(decimals), (value) => validateDecimals(value, decimals))

export const validateDecimals = (value: number | undefined, decimals: number | undefined) => {
  if (!value || !decimals) {
    return true
  }
  const valueDecimals = value.toString().split('.')[1]?.length ?? 0
  return valueDecimals <= decimals
}

export const minMaxLengthSchema = (label: string, min: number, max: number) =>
  yup
    .string()
    .min(min, `${label} should be min ${min} characters long`)
    .max(max, `${label} should be less than ${max} characters long`)

const linkErrorMessage = 'It has to be a valid URL'
const urlRegex =
  /^((https?):\/\/)?((www\.)?([a-z0-9-:]+(\.[a-z]{2,}){1,3})|localhost[:0-9]+)(#?\/?[a-zA-Z0-9#.-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
export const linkSchema = yup.string().matches(urlRegex, linkErrorMessage)
export const optionalLinkSchema = linkSchema.optional().transform((value) => value || undefined)

const phoneNumberRegex = /^[\d\-\s]*$/
export const phoneNumberSchema = yup
  .string()
  .transform((value) => value.trim())
  .matches(phoneNumberRegex, 'It has to be valid phone number')

export const addressSchema = () =>
  yup
    .string()
    .test('valid-address', 'It has to be valid Ethereum address', (value) => (value ? isAddress(value) : true))

export const fileSchema = (fileSize: number) =>
  yup.mixed().test('file-size', uploadError, (value) => (value ? value.size <= fileSize : true))

export const emailSchema = yup.string().email('Use a proper email format e.g. name@domain.com')
